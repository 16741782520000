import * as React from "react"
import { css, Global, SerializedStyles } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { typography, fonts, breakpoints, Theme } from "./theme"

const makeGlobalStyles = (theme: Theme): SerializedStyles => css`
  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  /* Typography */

  html {
    box-sizing: border-box;
    font-size: 16px;
    font-size: 100%;
    font-family: Merriweather, Georgia, serif;
    color: #222;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  @media screen and (min-width: 40em) {
    html {
      font-size: 18px;
      font-size: 1.125rem;
    }
  }

  p {
    line-height: 26px;
    line-height: 1.625rem;
    margin: 0px 0px 26px;
    margin: 0rem 0rem 1.625rem;
  }
  @media screen and (min-width: 40em) {
    p {
      line-height: 31px;
      line-height: 1.722222222222222rem;
      margin-bottom: 31px;
      margin-bottom: 1.722222222222222rem;
    }
  }

  /* Headings
   ========================================================================== */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${theme.ink};
    font-family: ${fonts.heading};
    text-rendering: optimizeLegibility;
  }

  h1 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 52px;
    line-height: 3.25rem;
    margin-top: 104px;
    margin-top: 5rem;
    margin-bottom: 26px;
    margin-bottom: 1.625rem;
  }

  h2 {
    font-size: 27px;
    font-size: 1.6875rem;
    line-height: 39px;
    line-height: 2.4375rem;
    margin-top: 65px;
    margin-top: 4.0625rem;
    margin-bottom: 13px;
    margin-bottom: 0.8125rem;
  }

  h3 {
    font-size: 22px;
    font-size: 1.375rem;
    line-height: 26px;
    line-height: 1.625rem;
    margin-top: 52px;
    margin-top: 3.25rem;
    margin-bottom: 13px;
    margin-bottom: 0.8125rem;
  }

  h4 {
    font-size: 19.2px;
    font-size: 1.2rem;
    line-height: 26px;
    line-height: 1.625rem;
    margin-top: 39px;
    margin-top: 2.4375rem;
    margin-bottom: 13px;
    margin-bottom: 0.8125rem;
  }

  h5 {
    font-size: 16px;
    font-size: 1rem;
    line-height: 26px;
    line-height: 1.625rem;
    margin-top: 65px;
    margin-top: 4.0625rem;
    margin-bottom: 13px;
    margin-bottom: 0.8125rem;
  }

  h6 {
    font-size: 16px;
    font-size: 1rem;
    line-height: 26px;
    line-height: 1.625rem;
    margin-top: 65px;
    margin-top: 4.0625rem;
    margin-bottom: 13px;
    margin-bottom: 0.8125rem;
  }

  @media screen and (min-width: 40em) {
    h1 {
      font-size: 40px;
      font-size: 2.5rem;
      line-height: 62px;
      line-height: 3.444444444444445rem;
      margin-top: 124px;
      margin-top: 5rem;
      margin-bottom: 31px;
      margin-bottom: 1.722222222222222rem;
    }

    h2 {
      font-size: 27px;
      font-size: 1.6875rem;
      line-height: 46.5px;
      line-height: 2.583333333333334rem;
      margin-top: 77.5px;
      margin-top: 4.305555555555556rem;
      margin-bottom: 15.5px;
      margin-bottom: 0.861111111111111rem;
    }

    h3 {
      font-size: 22px;
      font-size: 1.375rem;
      line-height: 31px;
      line-height: 1.722222222222222rem;
      margin-top: 62px;
      margin-top: 3.444444444444445rem;
      margin-bottom: 15.5px;
      margin-bottom: 0.861111111111111rem;
    }

    h4 {
      font-size: 19.2px;
      font-size: 1.2rem;
      line-height: 31px;
      line-height: 1.722222222222222rem;
      margin-top: 46.5px;
      margin-top: 2.583333333333334rem;
      margin-bottom: 15.5px;
      margin-bottom: 0.861111111111111rem;
    }

    h5 {
      font-size: 16px;
      font-size: 1rem;
      line-height: 31px;
      line-height: 1.722222222222222rem;
      margin-top: 77.5px;
      margin-top: 4.305555555555556rem;
      margin-bottom: 15.5px;
      margin-bottom: 0.861111111111111rem;
    }

    h6 {
      font-size: 16px;
      font-size: 1rem;
      line-height: 31px;
      line-height: 1.722222222222222rem;
      margin-top: 77.5px;
      margin-top: 4.305555555555556rem;
      margin-bottom: 15.5px;
      margin-bottom: 0.861111111111111rem;
    }
  }
  h1 + h2 {
    margin-top: 26px;
    margin-top: 1.625rem;
  }
  @media screen and (min-width: 40em) {
    h1 + h2 {
      margin-top: 31px;
      margin-top: 1.722222222222222rem;
    }
  }

  h2 + h3,
  h3 + h4,
  h4 + h5 {
    margin-top: 13px;
    margin-top: 0.8125rem;
  }
  @media screen and (min-width: 40em) {
    h2 + h3,
    h3 + h4,
    h4 + h5 {
      margin-top: 15.5px;
      margin-top: 0.861111111111111rem;
    }
  }

  h5 + h6 {
    margin-top: -13px;
    margin-top: -0.8125rem;
  }
  @media screen and (min-width: 40em) {
    h5 + h6 {
      margin-top: -15.5px;
      margin-top: -0.861111111111111rem;
    }
  }

  p {
    font-size: ${typography.bodyRem}rem;
    margin-top: ${0.5 * typography.bodyRem * typography.lineHeight}rem;
    margin-bottom: ${typography.bodyRem * typography.lineHeight}rem;
  }

  li {
    font-size: ${typography.bodyRem}rem;
  }

  small {
    font-size: ${typography.smallRem}rem;
  }

  /* Links */

  a {
    color: ${theme.link};
    text-decoration: none;

    &:hover,
    &:focus,
    &::visited {
      text-decoration: none;
      color: ${theme.link};
    }
  }

  body {
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    font-family: ${fonts.body};
    color: ${theme.ink};
    -webkit-transition: color 300ms linear;
    -ms-transition: color 300ms linear;
    transition: color 300ms linear;
    background-color: ${theme.canvas};
    -webkit-transition: background-color 300ms linear;
    -ms-transition: background-color 300ms linear;
    transition: background-color 300ms linear;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }

  img {
    max-width: 100%;
    object-fit: contain;
    position: relative;
  }

  figure {
    margin: 2rem 0;
  }

  figcaption {
    font-size: 80%;
  }

  table {
    width: 100%;
    margin-bottom: 1rem;
    border: 1px solid ${theme.borderDefault};
    font-size: 85%;
    border-collapse: collapse;
  }

  td,
  th {
    padding: 0.25rem 0.5rem;
    border: 1px solid ${theme.borderDefault};
  }

  th {
    text-align: left;
  }

  tbody {
    tr {
      &:nth-of-type(odd) {
        td {
          background-color: ${theme.tableRowBackground};
        }
        tr {
          background-color: ${theme.tableRowBackground};
        }
      }
    }
  }

  strong {
    color: ${theme.ink};
  }

  ul,
  ol,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  dt {
    font-weight: bold;
  }

  dd {
    margin-bottom: 0.5rem;
  }

  hr {
    position: relative;
    margin: 1.5rem 0;
    border: 0;
    border-top: 1px solid ${theme.borderDefault};
  }

  blockquote {
    margin: 0.8rem 0;
    padding: 0.5rem 1rem;
    border-left: 0.25rem solid ${theme.borderDefault};

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }

    @media (min-width: ${breakpoints.md}px) {
      padding-right: 5rem;
      padding-left: 1.25rem;
    }
  }

  .desktop-centered {
    text-align: center;
    @media screen and (max-width: ${breakpoints.sm}) {
      text-align: left;
    }
  }
`

const GlobalStyles: React.FC = () => {
  const theme = useTheme<Theme>()
  return <Global styles={makeGlobalStyles(theme)} />
}

export default GlobalStyles
