import originalStyled, { CreateStyled } from "@emotion/styled"

const palette = {
  dark: "#222831",
  light: "#f9f9f9",
  brand: "#fecb00",
  link: "#30475e",
  semiDark: "#393e46",
  semiLight: "#d2c9b1"
}

export type Theme = {
  symbol: string
  canvas: string
  ink: string
  paleInk: string
  homeLogoBackground: string
  homeLogoText: string
  link: string
  borderDefault: string
  tableRowBackground: string
  canvasLine: string
  shadow: string
  navTitle: string
}

export const styled: CreateStyled<Theme> = originalStyled

export const themes: { [key: string]: Theme } = {
  light: {
    symbol: "🌙",
    canvas: palette.light,
    ink: palette.dark,
    paleInk: palette.semiDark,
    homeLogoBackground: palette.dark,
    homeLogoText: palette.brand,
    link: palette.link,
    borderDefault: palette.semiLight,
    tableRowBackground: palette.semiLight,
    canvasLine: palette.semiLight,
    shadow: palette.semiDark,
    navTitle: palette.brand
  },
  dark: {
    symbol: "🌞",
    canvas: palette.dark,
    ink: palette.light,
    paleInk: palette.semiLight,
    homeLogoBackground: palette.brand,
    homeLogoText: palette.dark,
    link: palette.light,
    borderDefault: palette.semiDark,
    tableRowBackground: palette.semiDark,
    canvasLine: palette.semiDark,
    shadow: palette.semiLight,
    navTitle: palette.brand
  }
}

export const fonts = {
  heading: "Anton",
  body: "Oxygen",
  ingredients: "Yesteryear"
}

export const breakpoints = {
  xs: 0,
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px"
}

export const typography = {
  baseMinPx: 16,
  baseMaxPx: 26,
  lineHeight: 1.8,
  headingLineHeight: 1.2,
  h1Rem: 2.7,
  h2Rem: 2.2,
  h3Rem: 1.7,
  h4Rem: 1.4,
  h5Rem: 1.2,
  h6Rem: 0.9,
  bodyRem: 1,
  smallRem: 0.7
}

// The exported dimensions are values that affect other components.
// If a value is completely internal to a component, it is better stored inside the component.

const mobilePaddingVw = 5

export const dimensions = {
  // On mobile, everything lines up, but on desktop, we have a T-shaped layout
  mobileHorizontalPadding: `${mobilePaddingVw}vw`,
  mobileWidth: `${100 - 2 * mobilePaddingVw}vw`,
  articleMaxWidth: "40em",
  contentMaxWidth: "40em"
}
