import * as React from "react"

import { dimensions, styled } from "src/styles/theme"

const StyledFooter = styled.footer`
  height: 10vh;
  width: 100%;
  padding: 0 ${dimensions.mobileHorizontalPadding};
`

const Footer: React.FC = () => {
  return <StyledFooter />
}

export default Footer
