import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import GlobalStyles from "src/styles/GlobalStyles"
import "modern-normalize"

import { styled } from "src/styles/theme"
import Footer from "./Footer"

const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`

const Page: React.FC = ({ children }) => {
  const data = useStaticQuery<GatsbyTypes.PageQuery>(graphql`
    query Page {
      site {
        siteMetadata {
          title
          description
          keywords
        }
      }
    }
  `)

  return (
    <>
      <Helmet
        title={data?.site?.siteMetadata?.title}
        meta={[
          { name: "description", content: data?.site?.siteMetadata?.description },
          { name: "keywords", content: data?.site?.siteMetadata?.keywords }
        ]}
      />
      <GlobalStyles />
      <StyledPage>
        {children}
        <Footer />
      </StyledPage>
    </>
  )
}

export default Page
